.box__wrapper {
  background: rgba(40, 72, 91, 0.34);
  border-right: 2px double white;
  box-shadow: 1px 4px 14px rgba(255, 255, 255, 0.7) inset;
  cursor: move;
  text-align: left;
  width: 20%;
  min-width: 220px;
  max-width: 480px;
  color: #eeeeee;
  display: inline-block;
  transition: transform ease-in 0.2s;
  clip-path: polygon(
    60px 0,
    calc(100% - 20px) 0,
    100% 20px,
    100% 29%,
    96% 33%,
    96% 70%,
    100% 74%,
    100% calc(100% - 34px),
    calc(100% - 20px) 100%,
    0 100%,
    0 35px,
    5px 30px,
    40px 30px
  );
  position: absolute;
  left: 0;
  top: 0;
}
.box__wrapper .less {
  display: none;
}

.box__wrapper.fullMode {
  background: rgba(40, 72, 91, 0.84);
  cursor: auto;
  height: 60vh;
  left: 50% !important;
  max-height: 1000px;
  max-width: 800px;
  opacity: 1 !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  transition: top 0.2s, left 0.2s;
  width: 60vh;
  z-index: 100;

  &:hover {
    transform: translate(-50%, -50%) !important;
  }
  > div {
    height: 100%;
  }

  .less {
    display: inline-block;
    opacity: 1;
  }

  .more {
    display: none;
  }

  .box__content {
    height: calc(100% - 88px);
  }
}

.box__wrapper:hover {
  opacity: 1 !important;
  z-index: 100 !important;
  transform: unset !important;
}

.box__wrapper:hover > div {
  background: rgba(40, 72, 91, 0.84);
  transition: background ease-in 0.2s;
}

.box__title {
  padding: 6px 20px 6px 70px;
}
.box__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.box__header__input {
  background: transparent;
  border: 0 solid;
  color: #ffffff;
  font-size: 1em;
  margin: 0 10px 0 0;
  width: 38px;
  outline: 0 solid;
}

.box__content {
  height: 90px;
  line-height: 1.4em;
  min-height: 100px;
  padding: 4% 6%;
  overflow: hidden;
}

.box__footer {
  position: relative;
  text-align: right;
  padding-right: 12px;
}

.box__footer:before {
  border-bottom: 2px double white;
  border-left: 2px double white;
  content: '';
  display: block;
  height: 15px;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 15px;
}

.fadeOut,
.fadeOut:hover {
  opacity: 0 !important;
  z-index: -1;
}
