@import '../global';
button {
  background: rgba(213, 244, 255, 0.24);
  border: 3px double rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  display: inline-block;
  font-family: 'Cousine', monospace;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.2em 1.1em;
  transition: all ease-in 0.4s;
  transform: skew(-30deg)
}

button:hover {
  background: rgba(213, 244, 255, 0.14);
  color: #fff;
}

button span {
  display: block;
  height: 1.5rem;
  line-height: 1.5rem;
  transform: skew(30deg);
}

.alert {
  color: $alertColor;
  border-color: $alertColor;
}

.info {
  color: $infoColor;
  border-color: $infoColor;
}

.more {
  color: $moreColor;
  border-color: $moreColor;
}

.accept {
  color: $acceptColor;
  border-color: $acceptColor;
}
