.boxDashboard {
  position: relative;
  height: 100%;
}
#zoomer {
  left: 10px;
  position: absolute;
  top: 10px;
  z-index: 10;
}
