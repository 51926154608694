@import '../global';
.typography {
  ol, ul {
    margin: 0 0 0.8rem;
    padding: 0 0 0 1.6rem;
  }
  li {
    margin: 0 0 0.3rem;
    &:last-child {
      margin: 0;
    }
  }
  p {
    margin: 0 0 0.8rem;
  }
  a {
    color: $infoColor;
    transition: color 0.4s ease-in 0s;

    &:hover {
      color: $acceptColor;
    }
  }
}
